@tailwind base;
html {
  scroll-behavior: smooth;
}
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-body font-sans text-blue-secondary;
  }
  .h2 {
    @apply text-2xl lg:text-4xl text-blue font-bold;
  }
  .btn,
  .input-control {
    @apply text-[15px] h-16 rounded-full outline-none;
  }
  .btn {
    @apply px-8 font-medium text-white bg-green hover:bg-green-hover transition;
  }
  .input-control {
    @apply w-full max-w-[514px] px-8 text-blue placeholder:text-blue-secondary;
  }
}